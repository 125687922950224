<template>
  <div class="container-fluid">
    <div class="page-wrapper">
      <div slot="header" class="page-wrapper-header">
        <div class="page-wrapper-header-left spot">
          <div class="kw-back">
            <h3>{{ $t("APPS.CALENDAR_MODULE") }}</h3>
          </div>
          <div class="kw-breadcrumb">
            <ul>
              <li>
                <img src="/img/kw-home.svg" alt="icon" />
                <span>{{ $t("APPS.CALENDAR_MODULE") }}</span>
              </li>
              <!-- <li>
                <span>{{ $t("COMMON.CALENDARS_LIST") }}</span>
              </li> -->
            </ul>
          </div>
        </div>
      </div>
      <calendar-list-table />
    </div>
  </div>
</template>
<script>
import { Button } from "element-ui";
import requestErrorMixin from "@/mixins/request-error-mixin";
import CalendarListTable from "./partials/CalendarListTable.vue";

export default {
  layout: "DashboardLayout",

  components: {
    [Button.name]: Button,
    CalendarListTable,
  },

  mixins: [requestErrorMixin],

  computed: {
  },

  watch: {},

  data() {
    return {
      loading: false,
    };
  },

  mounted() {},

  methods: {},
};
</script>
